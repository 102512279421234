import React from 'react';
import { Link } from 'react-router-dom';
import CTA from '../../components/Common/CTA';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import PricingMain from '../Pricing';
import SingleProcess from '../../components/Common/Process';

// Service Image Icons
import membeddeddesign from '../../assets/img/service/style2/main-img/embedded product.png';
import hembeddeddesign from '../../assets/img/service/style2/hover-img/embedded product.png';
import membeddeddevelopemnt from '../../assets/img/service/style2/main-img/embedded development.png';
import hembeddeddevelopment from '../../assets/img/service/style2/hover-img/embedded development.png';
import mpcb from '../../assets/img/service/style2/main-img/pcb.png';
import hpcb from '../../assets/img/service/style2/hover-img/pcb.png';
import msoftwaredevelopment from '../../assets/img/service/style2/main-img/software development.png';
import hsoftwaredevelopment from '../../assets/img/service/style2/hover-img/software development.png';
import mindustrialautomation from '../../assets/img/service/style2/main-img/industrial automation.png';
import hindustrialautomation from '../../assets/img/service/style2/hover-img/industrial automation.png';
import mdevicedriver from '../../assets/img/service/style2/main-img/device driver.png';
import hdevicedriver from '../../assets/img/service/style2/hover-img/device driver.png';
import mos from '../../assets/img/service/style2/main-img/operating system.png';
import hos from '../../assets/img/service/style2/hover-img/operating system.png';

const ServiceTwoMain = () => {
    return (
        <React.Fragment>
            {/* services-area-start */}
            <div className="rs-services style3 gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-20">
                            <SingleServiceTwo
                                itemClass="services-item"
                                prefix="01"
                                mainIcon={membeddeddesign}
                                hoverIcon={hembeddeddesign}
                                Title="Embedded Product Design"
                                Text="Embedded design using 8, 16, 32 bit microcontroller & ARM processors."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-20">
                            <SingleServiceTwo
                                itemClass="services-item pink-bg"
                                prefix="02"
                                mainIcon={membeddeddevelopemnt}
                                hoverIcon={hembeddeddevelopment}
                                Title="Embedded Development"
                                Text="Developing software and firmware for embedded systems."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-20">
                            <SingleServiceTwo
                                itemClass="services-item aqua-bg"
                                prefix="03"
                                mainIcon={mpcb}
                                hoverIcon={hpcb}
                                Title="PCBs"
                                Text="Service for the design of printed circuit boards."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-20">
                            <SingleServiceTwo
                                itemClass="services-item paste-bg"
                                prefix="04"
                                mainIcon={msoftwaredevelopment}
                                hoverIcon={hsoftwaredevelopment}
                                Title="Software Development"
                                Text="Customised software development using VB, SQL, C/C++ and ASP.NET."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-20">
                            <SingleServiceTwo
                                itemClass="services-item purple-bg"
                                prefix="05"
                                mainIcon={mindustrialautomation}
                                hoverIcon={hindustrialautomation}
                                Title="Industrial Automation"
                                Text="Lab View based industrial utomation services."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <SingleServiceTwo
                                itemClass="services-item green-bg"
                                prefix="06"
                                mainIcon={mdevicedriver}
                                hoverIcon={hdevicedriver}
                                Title="Device Drivers Development"
                                Text="Design and development of  Device Drivers for customized hardware."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-20">
                            <SingleServiceTwo
                                itemClass="services-item red-bg"
                                prefix="07"
                                mainIcon={mos}
                                hoverIcon={hos}
                                Title="OS Porting"
                                Text="Operating system porting, Linux device driver development."
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* services-area-end */}

            {/* newsletter-area-start */}
            <CTA
                ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
                ctaTitleClass="epx-title"
                ctaTitle="Grow Your Business with us."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="#"
                ctaButtonText="Get In Touch"
            />
            {/* newsletter-area-end */}
        </React.Fragment>
    );
}

export default ServiceTwoMain;