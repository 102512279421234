import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

import SectionTitle from '../../components/Common/SectionTitle';
import SingleTeam from '../../components/Team/SingleTeam';
import SingleProcess from '../../components/Process/SingleProcess';
import SinglePricing from '../../components/Pricing/SinglePricing';
import VideoStyleThree from '../../components/Video/VideoStyleThree';


// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage2 from '../../assets/img/process/2.png';
import processImage3 from '../../assets/img/process/3.png';
import processImage4 from '../../assets/img/process/4.png';

// Working Process Images
import teamImg1 from '../../assets/img/team/1.jpg';
import teamImg2 from '../../assets/img/team/2.jpg';
import teamImg3 from '../../assets/img/team/3.jpg';
import teamImg4 from '../../assets/img/team/4.jpg';
import teamImg5 from '../../assets/img/team/5.jpg';
import teamImg6 from '../../assets/img/team/6.jpg';
import teamImg7 from '../../assets/img/team/7.jpg';

import aboutImg from '../../assets/img/about/about-3-modified.png';
import danceEffectImg from '../../assets/img/about/effect-1.png';

// Pricing Image
import silverImg from '../../assets/img/pricing/1.png';
import goldImg from '../../assets/img/pricing/2.png';
import platinumImg from '../../assets/img/pricing/3.png';

import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';

const AboutMain = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="rs-about" className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 md-mb-30">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={aboutImg} alt="" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="About Us"
                                    titleClass="title pb-36"
                                    title="We Are Increasing Business Success With Technology"
                                    descClass="desc pb-34"
                                    description="Over 16 years working in Embedded Systems development and designing for clients all over the world."
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription="Samslogix provides unique hardware and software engineering design services, including product development from concept through prototype and production-ready designs. Samslogix adheres to the entire product development cycle, from requirements to design, implementation, testing, and validation, in order to meet the needs of the customer."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/contact">Learn-More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div>
                </div>
            </div>
            {/* about-area-end */}

            {/* working-process-area-start */}
            <div className="rs-process style2 pt-120 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title2"
                        title="Our Working Process"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discovery"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage2}
                                titleClass="title"
                                Title="Planning"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage3}
                                titleClass="title"
                                Title="Execute"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage4}
                                titleClass="title"
                                Title="Deliver"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}

            {/* CEO Message Start */}
            <div id="rs-about" className="rs-about bg17 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                        <div className="images">
                            <img src={aboutImg} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            titleClass="title pb-38"
                            title="CEO's Quote"
                            descClass="desc pb-36"
                            description="Our mission is to comprehend the needs and expectations of customers and other interested parties, and to equip them with all of their problem-solving talents in order to accomplish these objectives. Perform the activities and responsibilities assigned to each member of the organisation in a responsible manner, treating the colleague who is downstream in any activity as their client and the colleague who is upstream as their customer. Always and accurately describe the value proposition's components as well as the quality that the customer expects and perceives."
                            secondDescClass="margin-0 pb-16"
                            //secondDescription="We keep our knowledge current in order to supply our customers with the most advanced technology and to pursue technical innovation. To produce appropriate profits to fund development expenditures by managing available resources efficiently, delivering with the lowest possible defect index, offering new solutions that meet customer requirements and expectations, and enhancing the value proposition. Creating comprehensive solutions that meet the demands and expectations of customers raises the brand's perceived worth."
                        />
                        {/* Section Title End */}
                    </div>
                </div>
            </div>
            </div>
        {/* CEO Message End */}
            
           
        </React.Fragment>
    )
}

export default AboutMain;