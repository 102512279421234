import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const Process = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div id="rs-portfolio" className="rs-process style4 pt-120 pb-120 md-pt-80 md-pb-70" style={bgStyle}>
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='vWLcyFtni6U' onClose={() => { openModal(); }} />
            <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">Working Process</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-4 pr-30 md-pl-14 md-pb-100">
                        <div className="rs-step">
                        </div>
                    </div>
                    <div className="col-lg-8 pl-34 md-pl-14">
                        <div className="row">
                            <div className="col-md-6 mb-60">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Our first impulse is to act."
                                    Text="Our dedication to our main mission gives us the confidence to pursue unique and unconventional concepts outside of our comfort zone."
                                />
                            </div>
                            <div className="col-md-6 mb-60">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Every Day, Make a Difference"
                                    Text="Constantly pushing ourselves to be our best, focusing on solutions, and arriving each day inspired to make an impact."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-60">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Do what's right."
                                    Text="Our teams prioritise acting with integrity and candour and putting ourselves in the shoes of others."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleServiceNumber 
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Client Value Creation"
                                    Text="Building lasting connections while helping customers achieve commercial success."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;