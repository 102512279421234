import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu, activeMenu } = props;

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
                <Link to="/" className={activeMenu === "/" ? "active-menu" : ""}>Home</Link>
            </li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/about" className={activeMenu === "/about" ? "active-menu" : ""}>About</Link>
            </li>
            <li className={parentMenu === 'service' ? 'current-menu-item' : ''}>
                <Link to="/service" className={activeMenu === "/service" ? "active-menu" : ""}>Services</Link>
            </li>
            <li className={parentMenu === 'product' ? 'current-menu-item' : ''}>
                <Link to="/product" className={activeMenu === "/product" ? "active-menu" : ""}>Products</Link>
            </li>
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/contact" className={activeMenu === "/contact" ? "active-menu" : ""}>Contact</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;