import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';
import ScrollToTop from '../../components/Common/ScrollTop';

import bannerbg from '../../assets/img/breadcrumbs/6.jpg';


const ContactMain = () => {

        return (
            <React.Fragment>

                {/* SiteBreadcrumb */}
                <SiteBreadcrumb
                    pageTitle="Contact"
                    pageName="Contact"
                    breadcrumbsImg={bannerbg}
                />
                {/* SiteBreadcrumb */}

                {/* Contact Section Start */}
                <div className="rs-contact pt-120 md-pt-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 md-mb-60">
                                <ContactInfo />
                            </div>
                            <div className="col-lg-8 pl-70 md-pl-14">
                                <div className="contact-widget">
                                    <SectionTitle
                                        sectionClass="sec-title2 mb-40"
                                        subtitleClass="sub-text contact mb-14"
                                        subtitle="Get In Touch"
                                        titleClass="title testi-title"
                                        title="Fill The Form Below"
                                    />

                                    {/* Contact Form */}
                                    <ContactForm />
                                    {/* Contact Form */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="map-canvas pt-120 md-pt-80">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7772.191079507378!2d77.39534027109802!3d13.093131213057562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%235718%2C%20Behind%20KEB%2C%20Subash%20Nagar%2C%20Nelamangala%2C%20Bangalore!5e0!3m2!1sen!2sin!4v1663433479638!5m2!1sen!2sin"></iframe>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
                {/* Contact Section End */}
            </React.Fragment>

        );
}


export default ContactMain;