import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';



//Custom Components

import HomeEight from '../pages/home-8';
import About from '../pages/about';
import ServiceTwo from '../pages/service/service-2';
import CaseStudtyStyleSix from '../pages/case-study/style-6';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    return (
        <div className='App'>
            <Router>  
                <LoadTop />          
                <Switch>
                    <Route path="/" exact component={HomeEight} />
                    <Route path="/about" component={About} />
                    <Route path="/service" exact component={ServiceTwo} />
                    <Route path="/product" exact component={CaseStudtyStyleSix} />
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                </Switch>
            </Router>
            
        </div>
    );
}

export default App;
