
import React, { useEffect, useState } from 'react';

const ContactForm = (props) => {
    const { submitBtnClass } = props;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [website, setWebsite] = useState("");
    const [message, setMessage] = useState("");
    // useEffect(()=>{
    //     if(name.length>0 &&
    //         email.length>0 &&
    //         number.length>0 &&
    //         website.length>0 &&
    //         message.length>0){
    //             handleClick()
    //         }
    // },[name,email,number,website,message])
    async function handleClick(){
        const jsonobj={
            name: name,
            email: email,
            number: number,
            website: website.length>0? website:" ",
            message: message
        }
        alert(JSON.stringify(jsonobj));
        await fetch('https://us-central1-samslogix-website.cloudfunctions.net/SendMail', { 
            method:'POST', 
            mode: 'no-cors',
            headers:  {
                'Content-Type': 'application/json'},
            body: JSON.stringify(jsonobj),
        }).then(response =>
        { if (!response.status) {
            throw new Error(JSON.stringify(response))
          }
          console.log(JSON.stringify(response))
          alert(response.data)
        }).catch(err=>{
        console.log(err)})
        setName("");
        setEmail("");
        setNumber("");
        setWebsite("");
        setMessage("");
    }
    return (
        <form id="contact-form" action="#">
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="name" placeholder="Name" required value={name} onChange={e => setName(e.target.value)} />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="email" name="email" placeholder="E-Mail" required value={email} onChange={e => setEmail(e.target.value)}/>
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required value={number} onChange={e => setNumber(e.target.value)}/>
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="website" name="website" placeholder="Your Website" required value={website} onChange={e => setWebsite(e.target.value)}/>
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="message" placeholder="Your message Here" required value={message} onChange={e => setMessage(e.target.value)}></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button onClick={handleClick} type="button">Submit Now</button>
            </div>
        </form>
    );

}

export default ContactForm;