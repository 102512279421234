import SingleService from '../../components/Service/SingleService';

// Service Image Icons
import serviceIcon1 from '../../assets/img/service/style6/1.png';
import serviceIcon2 from '../../assets/img/service/style6/2.png';
import serviceIcon3 from '../../assets/img/service/style6/3.png';
import serviceIcon4 from '../../assets/img/service/style6/4.png';
import serviceIcon5 from '../../assets/img/service/style6/5.png';
import serviceIcon6 from '../../assets/img/service/style6/6.png';

import bgImg from '../../assets/img/bg/services-bg.jpg';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const Service = () => {

    return (
        <div id="rs-service" className="rs-services main-home style3 pt-120 pb-120 md-pt-80 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">Our Domain of Expertise</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            serviceURL="industrial-automation"
                            Title="Industrial Automation" 
                            Text="Using control systems to facilitate the automation of labor-intensive processes." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            serviceURL="automotive"
                            Title="Automotive" 
                            Text="Designing and manufacturing of independent propulsion machines for the automobile industry." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="medical-equipment"
                            Title="Medical Equipment" 
                            Text="Specialised equipment to help the diagnosis and treatment of medical disorders." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 md-mb-24">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            serviceURL="rfid"
                            Title="RFID" 
                            Text="Simplifying tracking of items or people using RFID-enabled transfer and communication." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 sm-mb-24">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon5}
                            serviceURL="home-automation"
                            Title="Home Automation" 
                            Text="Using electronic control over physical items to automate household characteristics and activities." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 ">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            serviceURL="iot"
                            Title="IoT" 
                            Text="Interfacing real-world objects with digital networks by the incorporation of on-board sensors and programme code." 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;