import SectionTitle from '../../components/Common/SectionTitle';

import techImg1 from '../../assets/img/technology/style2/SAP logo.png';
import techImg2 from '../../assets/img/technology/style2/rotart electronics logo.png';
import techImg3 from '../../assets/img/technology/style2/indian-fibre-logo.jpg';
import techImg4 from '../../assets/img/technology/style2/BPL_Logo.png';
import techImg5 from '../../assets/img/technology/style2/crown-power-fencing-system-mettupalayam logo.png';
import techImg6 from '../../assets/img/technology/style2/genius-industrial-services logo.png';
import techImg7 from '../../assets/img/technology/style2/advance technologies logo.png';
import techImg8 from '../../assets/img/technology/style2/redmed-logo.png';
import techImg9 from '../../assets/img/technology/style2/stark-tech logo.jpg';
import techImg10 from '../../assets/img/technology/style2/10.png';
import techImg11 from '../../assets/img/technology/style2/11.png';
import techImg12 from '../../assets/img/technology/style2/12.png';


const Technology = (props) => {
    return (
        <div className="rs-technology gray-bg pt-120 pb-130 md-pt-70 md-pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    titleClass="title title2"
                    title="Our valued customers"
                />
                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg1} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg2} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg3} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg4} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg5} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg6} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg7} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg8} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg9} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Technology