import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';


import Newsletter from '../../Common/Newsletter';

import footerLogo1 from '../../../assets/img/logo/logo-nobg.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4" style={{maxWidth:"200px"}}>
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p></p>
                            </div>
                            <ul className="footer-social md-mb-30">
                                <li>
                                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-pinterest"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-instagram"></i></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30" style={{minWidth:"370px"}}>
                            <h3 className="widget-title">Services</h3>
                            <ul className="site-map">
                                <li><Link to="/service/software-development">Embedded Software and Firmware Development</Link></li>
                                <li><Link to="/service/Web-development">Printed Circuit Board Designing</Link></li>
                                <li><Link to="/service/analytic-solutions">Industrial Automation Services</Link></li>
                                <li><Link to="/service/cloud-and-devOps">Custom Software Development</Link></li>
                                <li><Link to="/service/product-design">Design and Development of Device Drivers</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contact Info</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">#5718, Behind KEB, Subash Nagar, Nelamangala, Bangalore - 562123</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <Link to="#">+91 9900514078</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <Link to="#">sampath4078@gmail.com</Link>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        Opening Hours: 10:00 - 18:00
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <h3 className="widget-title">Newsletter</h3>
                            <p className="widget-desc">Subscribe to our news letter to know more.</p>
                            <p>
                                {/* newsletter-area-start */}
                                <Newsletter />
                                {/* newsletter-area-end */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;