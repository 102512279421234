import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
// About Image Icons
import aboutImg from '../../assets/img/about/about-7.png';

// Service Image Icons
import leftShapeImg from '../../assets/img/service/shape-2.png';
import rightShapeImg from '../../assets/img/service/shape-3.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about relative style3 pt-120 pb-120 md-pt-74 md-pb-80">
            <div className="container relative">
                <div className="row align-items-center">
                    <div className="col-lg-6 pr-56 md-pl-14 md-mb-30">
                        <SectionTitle
                            sectionClass="sec-title mb-30 zIndex"
                            subtitleClass="sub-text"
                            subtitle=""
                            titleClass="title title3 pb-30"
                            title="Samslogix - Embedded Design and development Since 2014"
                            descClass="desc pb-30"
                            description="Over 16 years of experience producing the most effective solutions for all of our clients using embedded systems and services."
                            secondDescClass="desc-part"
                            secondDescription="For more than 16 years, we've been the industry standard for embedded system development. Because our customers are so important to us, we involve them in every step of the process and devote ourselves to providing them with the finest solutions and  services available."
                        />
                        <div className="btn-part mt-40">
                            <Link className="readon learn-more learn-btn" to="/about">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="home8-about zIndex">
                            <img 
                                src={aboutImg}
                                alt="images" 
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-part">
                <div className="left-side">
                    <img 
                        src={leftShapeImg}
                        alt="" 
                    /> 
                </div>
                <div className="right-side">
                    <img 
                        src={rightShapeImg} 
                        alt="" 
                    /> 
                </div>
            </div>
        </div>
    );
}

export default About;