import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CaseStyleSix from '../../components/CaseStudy/CaseStyleSix';

// Breadcrumb Image
import bannerbg from '../../assets/img/breadcrumbs/5.jpg';

// Case Images
import image1 from '../../assets/img/project/data logger.jpg'
import image2 from '../../assets/img/project/rfid.jpg'
import image3 from '../../assets/img/project/signal conditioner.jpg';
import image4 from '../../assets/img/project/astronomical light.jpg';
import image5 from '../../assets/img/project/gps.jpg';
import image6 from '../../assets/img/project/iot.jpg';
import image7 from '../../assets/img/project/home automation.jpg'

const CaseStudtyStyleSix = () => {

    return (
        <React.Fragment>
            {/* Header */}
            <Header
                parentMenu='product'
                activeMenu='/product'
            />
            {/* Header */}

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Our Products"
                pageName="Our Products"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            {/* CaseStudy Start */}
            <div className="rs-project style8 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image1}
                                caseTitle="RS485 based industrial process control data logger"
                                caseCategory="Embedded Systems"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image1}
                                caseTitle="Zigbee based industrial process control data logger"
                                caseCategory="Embedded Systems"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image2}
                                caseTitle="RFID reader - Low Frequency (125-134KHz)"
                                caseCategory="RFID"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 md-mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image2}
                                caseTitle="RFID reader - High Frequency (13.56MHz)"
                                caseCategory="RFID"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 sm-mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image4}
                                caseTitle="GPS based astronomical light switch"
                                caseCategory="Automation"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image3}
                                caseTitle="PT100 to current loop signal conditioner"
                                caseCategory="Medical Equipment"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image5}
                                caseTitle="GPS and GPRS based vehicle tracker"
                                caseCategory="Home Automation"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image6}
                                caseTitle="IoT enabled embedded devices"
                                caseCategory="Internet Of Things"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6  mb-30">
                            <CaseStyleSix
                                caseClass="project-item"
                                caseImage={image7}
                                caseTitle="Home Automation Products"
                                caseCategory="Home Automation"
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* CaseStudy End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            {/* Footer */}
            <Footer />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}
        </React.Fragment>

    );
}


export default CaseStudtyStyleSix;